/* Base Styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  color: #333;
  margin: 0;
  padding: 0;
}

div {
  box-sizing: border-box;
}

.App {
  text-align: left;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media print {
  .pagebreak {
    page-break-before: always;
  } /* page-break-after works, as well */
}

/* Form Styles */
form {
  margin-bottom: 20px;
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

input[type="string"] {
  width: 80%; /* Since this isn't a standard input type, assuming you mean a text input */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none; /* To remove up and down arrows from number input in Webkit browsers */
}

button {
  background-color: #2980b9;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #3498db;
}


table {
  /* border: 2px solid rgb(77, 47, 184); */
  /* width: 100%; */
  table-layout: fixed;
  margin-left: auto;
  margin-right: auto;
  /* align: center; */
  /* height: 200px; */
}

th {
  border-bottom: 1px solid black;
  text-align: center;
  font-size: calc(1px + 2vmin);
}

td {
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  font-size: calc(1px + 2vmin);
}

th[scope="row"] {
  background-color: #d7d9f2;
}

th[scope="corner"] {
  background-color: #c1c3dc;
}

table,
th,
td {
  border: 1px solid;
}

tr:nth-child(even) {
  background-color: #bebcbc;
}

/* Responsive Media Queries */
@media (max-width: 768px) {
  input,
  button {
      width: 100%;
  }
}

/* ---------------------------------------------- */
/* Path: frontend/src/components/CustomAuthenticator.js */

.auth-panel-style {
  display: "flex";
  flex-direction: "column";
  align-items: "center";
  justify-content: "center";
  height: "100vh";
  background-color: "#f4f4f8";
}

.title-block {
  margin-bottom: "1em";
  color: "#333";
  text-align: "center";
}



/* ---------------------------------------------- */
/* Path: frontend/src/components/HeaderBar.js */
.header-bar-styles {
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: #fff;
}
